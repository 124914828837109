@import "~font-awesome/css/font-awesome.min.css";
@import "~typeface-roboto/index.css";

body {
  background-color: rgb(232, 232, 232);
  color: #545454;
  min-height: 100%;
  padding-bottom: 56px; /* For navigation bottom to never to on top on another UI element */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
